@charset "utf-8";
/*=======================================
「reset.css」には各タグの初期値を設定しています。
=======================================*/

/*******************************************
Font-size Adjustment
63% = 10px	|	100% = 16px	|	138% = 22px
69% = 11px	|	107% = 17px	|	144% = 23px
75% = 12px	|	113% = 18px	|	150% = 24px
82% = 13px	|	119% = 19px	|	157% = 25px
88% = 14px	|	125% = 20px	|	163% = 26px
94% = 15px	|	132% = 21px	|
********************************************/


/*---------------------------------------
Reset
----------------------------------------*/
html, body,
h1, h2, h3, h4, h5, h6,
div, p, dl, dt, dd, ol, ul, li,
span, a, strong, em, b, i, hr, del, dfn, img, ins, kbd, q, samp, small, sub, sup, var,
table, caption, tbody, tfoot, thead, tr, th, td,
fieldset, form, label, legend,
object, iframe, blockquote, pre, abbr, address, cite, code,
article, aside, canvas, details, figcaption, figure,
footer, header, main, menu, nav, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    font-weight: normal;
    vertical-align: baseline;
    background: transparent;
}

:focus {
    outline: 0;
}

html {
    overflow-y: scroll;
}

body {
    font-family: Noto Sans JP, -apple-system, BlinkMacSystemFont, "Helvetica Neue", "游ゴシック Medium", YuGothic, YuGothicM, "Hiragino Kaku Gothic ProN", メイリオ, Meiryo, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.8;
    color: #333;
    -webkit-text-size-adjust: 100%;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    body {
        font-size: medium;
    }
}

header, main, menu, nav, section, article, aside, details, figcaption, figure, footer {
    display: block;
}

input, select, textarea, option, optgroup, button {
    vertical-align: middle;
    font-family: inherit;
    font-size: 100%;
    font-weight: normal;
}

input[type="password"] {
    font-family: Arial, Helvetica, sans-serif;
}

img {
    border: 0;
}

strong {
    font-weight: bold;
}

table {
    border-collapse: collapse;
}

ul, ol {
    list-style: none;
}

em, address {
    font-style: normal;
}