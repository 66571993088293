@charset "utf-8";
/*---------------------------------------
Common
----------------------------------------*/
@import "object/common/*";
@import "foundation/common/*";

/*---------------------------------------
Common
----------------------------------------*/
th, td {
    vertical-align: middle;
}

caption, th, td {
    text-align: left;
}

p {
    margin-bottom: 15px;
}

a {
    color: #003BEB;
    &:hover {
        color: #FF4C00;
    }
    &:visited {
        color: #6C459E;
    }
}

.center {
    text-align: center;
}

.right {
    text-align: right;
}

.left {
    text-align: left;
}

.faceBold {
    font-weight: bold;
}

.faceBoldRed {
    color: #FF4C00;
    font-weight: bold;
}

body {
    min-width: 1000px;
    &.pagePopup {
        min-width: inherit;
        & #contents {
            margin: 0 auto;
            width: 500px;
        }
    }
}

:focus::-webkit-input-placeholder {
    color: transparent;
}

:focus:-moz-placeholder {
    color: transparent;
}

:focus::-moz-placeholder {
    color: transparent;
}

/*---------------------------------------
#contents
----------------------------------------*/
#contents {
    margin: 0 auto;
    width: 1000px;
    & img {
        &.right {
            margin: 0 0 10px 25px;
            float: right;
        }
        &.left {
            margin: 0 25px 10px 0;
            float: left;
        }
        &.center {
            margin: 0 auto 15px;
            display: block;
            clear: both;
        }
    }
}

/*---------------------------------------
ModalArea
----------------------------------------*/
#lean_overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background: #000;
    display: none;
    z-index: 10000;
}

#searchModalArea,
#counselingModalArea,
.modalArea {
    margin: 0 auto;
    border-radius: 10px;
    width: 900px;
    background-color: rgb(255, 255, 255);
    -moz-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.95);
    -webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.95);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.95);
    display: none;
}

#searchModalArea .modalHead,
#counselingModalArea .modalHead,
.modalArea .modalHead {
    padding: 10px 20px 8px;
    border-radius: 10px 10px 0 0;
    color: #FFF;
    font-size: 113%;
    font-weight: bold;
    background-color: #2A65B7;
}

#searchModalArea .modalClose,
#counselingModalArea .modalClose {
    margin: -37px 0 5px 800px;
    width: 80px;
    display: block;
}

#searchModalArea .searchModalBox {
    padding: 20px 9px 11px;
    background: url(/assets/pc/images/search_modal_bg.png) no-repeat 26px 20px;
}

#searchModalArea .searchModalBox strong {
    padding: 1px 0 18px 169px;
    display: block;
}

#searchModalArea .searchModalBox strong img {
    display: block;
}

#searchModalArea .searchModalBox p {
    margin-bottom: 0;
    padding-left: 169px;
    line-height: 1.6;
}

#searchModalArea .searchModalBox p em {
    color: #FF4C00;
}

#searchModalArea .searchModalBox form {
    padding: 12px 8px 8px 19px;
    background-color: #DFEAF9;
}

#searchModalArea .searchModalBox form em {
    margin-right: 5px;
    font-size: 113%;
    font-weight: bold;
    line-height: 1.2;
    vertical-align: middle;
    display: inline-block;
}

#searchModalArea .searchModalBox form input[type*="text"] {
    margin-right: 16px;
    padding: 5px 10px;
    border: 2px solid #777;
    border-radius: 5px;
    box-sizing: border-box;
    width: 320px;
    background-color: #FFF;
    box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.6);
    transition: border-color 0.5s, background-color 0.5s;
    display: inline-block;
}

#searchModalArea .searchModalBox form .buttonSearch {
    width: 189px;
    height: 45px;
    border: none;
    background: url(/assets/pc/images/button_sprite.png) no-repeat 0 -710px;
    text-indent: 110%;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    cursor: pointer;
}

#searchModalArea .searchModalBox form .buttonSearch:hover {
    background: url(/assets/pc/images/button_sprite.png) no-repeat -195px -710px;
}

#counselingModalArea .counselingModalBox {
    padding: 20px 20px 17px 20px;
}

#counselingModalArea .counselingModalBox .counselingModalBlock {
    padding: 14px 0 3px 116px;
    border-radius: 10px;
    background: #FFF3E8 url(/assets/pc/images/counseling_modal_bg.png) no-repeat 111px 66px;
}

#counselingModalArea .counselingModalBox .counselingModalBlock strong {
    margin-bottom: 17px;
    display: block;
}

#counselingModalArea .counselingModalBox .counselingModalBlock strong img {
    display: block;
}

#counselingModalArea .counselingModalBox .counselingModalBlock .freePhoneBox {
    padding-left: 109px;
}

#counselingModalArea .counselingModalBox .counselingModalBlock .freePhoneBox b {
    margin-bottom: 10px;
    display: block;
}

#counselingModalArea .counselingModalBox .counselingModalBlock .freePhoneBox b .number {
    padding: 0 0 0 68px;
    background: #FFF3E8 url(/assets/pc/images/icon_free_phone_03.png) no-repeat left 7%;
    background-size: 59px auto;
    color: #319F2C;
    font-size: 368%;
    font-weight: bold;
    line-height: 1;
    word-wrap: break-word;
    display: block;
}

#counselingModalArea .counselingModalBox .counselingModalBlock .freePhoneBox .poweredBy {
    margin: -8px 118px 0 0;
    display: block;
    color: #777;
    font-size: 83%;
    text-align: right;
}

#counselingModalArea .counselingModalBox ul {
    padding: 32px 330px 0 40px;
    background: url(/assets/pc/images/counseling_image.gif) no-repeat 490px 20px;
}

#counselingModalArea .counselingModalBox ul li {
    padding-left: 35px;
    font-size: 150%;
    background: url(/assets/pc/images/icon_check.gif) no-repeat left 0.35em;
    line-height: 40px;
}

#counselingModalArea .counselingModalBox a.buttonCounseling {
    margin: -104px 0 0 542px;
    width: 290px;
    height: 61px;
    border: none;
    background: url(/assets/pc/images/button_sprite.png) no-repeat 0 -817px;
    text-indent: 110%;
    overflow: hidden;
    white-space: nowrap;
    vertical-align: middle;
    display: inline-block;
}

#counselingModalArea .counselingModalBox a.buttonCounseling:hover {
    background: url(/assets/pc/images/button_sprite.png) no-repeat -296px -817px;
}

#counselingModalArea .counselingModalBox .mailArea {
    padding: 14px 0 14px 43px;
    background-color: #DFEAF9;
    letter-spacing: -0.4em;
}

#counselingModalArea .counselingModalBox .mailArea strong {
    margin-right: 136px;
    padding-left: 1px;
    vertical-align: middle;
    display: inline-block;
}

#counselingModalArea .counselingModalBox .mailArea strong img {
    display: block;
}

#counselingModalArea .counselingModalBox .mailArea a.buttonMail {
    width: 286px;
    height: 47px;
    border: none;
    background: url(/assets/pc/images/button_sprite.png) no-repeat 0 -761px;
    text-indent: 110%;
    overflow: hidden;
    white-space: nowrap;
    vertical-align: middle;
    display: inline-block;
}

#counselingModalArea .counselingModalBox .mailArea a.buttonMail:hover {
    background: url(/assets/pc/images/button_sprite.png) no-repeat -292px -761px;
}


/*---------------------------------------
.mapSearchArea
----------------------------------------*/
.mapSearchArea {
    padding: 0 0 0 54px;
}

.mapSearchArea .mapSearchBox {
    width: 742px;
    min-height: 366px;
    background: url(/assets/pc/images/map_bg.png) no-repeat 0 0;
    position: relative;
}

.mapSearchArea .mapSearchBox ul {
    padding: 4px 1px 2px 3px;
    background-color: rgba(223, 234, 249, 0.9);
    border-radius: 3px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.54);
    position: absolute;
}

.mapSearchArea .mapSearchBox ul.area01 {
    width: 61px;
    top: 25px;
    right: 56px;
}

.mapSearchArea .mapSearchBox ul.area02 {
    width: 124px;
    top: 127px;
    right: 4px;
}

.mapSearchArea .mapSearchBox ul.area03 {
    width: 124px;
    top: 232px;
    right: 52px;
}

.mapSearchArea .mapSearchBox ul.area04 {
    width: 124px;
    top: 27px;
    right: 222px;
}

.mapSearchArea .mapSearchBox ul.area05 {
    width: 124px;
    top: 288px;
    right: 192px;
}

.mapSearchArea .mapSearchBox ul.area06 {
    width: 124px;
    top: 101px;
    right: 361px;
}

.mapSearchArea .mapSearchBox ul.area07 {
    width: 124px;
    top: 100px;
    left: 110px;
}

.mapSearchArea .mapSearchBox ul.area08 {
    width: 124px;
    top: 288px;
    left: 280px;
}

.mapSearchArea .mapSearchBox ul.area09 {
    width: 124px;
    top: 218px;
    /* stylelint-disable-next-line */
    left: 11x;
}

.mapSearchArea .mapSearchBox ul.area10 {
    width: 61px;
    top: 66px;
    left: 34px;
}

.mapSearchArea .mapSearchBox li {
    margin: 0 2px 2px 0;
    float: left;
    width: 60px;
}

.mapSearchArea .mapSearchBox li a {
    padding: 4px 0;
    background-image: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgb(246, 246, 244) 50%, rgb(233, 232, 228) 100%);
    width: 58px;
    min-height: 14px;
    border: 1px solid rgb(119, 119, 119);
    border-radius: 2px;
    text-align: center;
    text-decoration: none;
    line-height: 1;
    display: block;
}

.mapSearchArea .mapSearchBox li a:hover {
    background-image: linear-gradient(180deg, rgb(232, 232, 232) 0%, rgb(255, 255, 255) 100%);
}


/*---------------------------------------
.help
----------------------------------------*/
a.help,.help.onlineText {
    padding: 0 28px 0 0;
    color: #333;
    font-weight: bold;
    background: url(/assets/pc/images/icon_question.png) no-repeat right center;
    position: relative;
    text-decoration: none;
    display: inline-block;
    div {
        padding: 2px;
        display: none;
        width: 500px;
        border: 3px solid #666;
        position: absolute;
        background: #FFFFF0;
        color: #333;
        top: 37px;
        left: 65px;
        white-space: normal;
        animation: tooltipHelp 0.3s ease-in-out 1;
        transform-origin: left top;
        z-index: 1;
        & > span {
            padding: 10px;
            border: 1px solid #666;
            font-size: 82%;
            font-weight: normal;
            line-height: 1.5;
            text-align: left;
            display: block;
            b {
                margin-bottom: 10px;
                font-size: 113%;
                font-weight: bold;
                /* stylelint-disable-next-line */
                font-weight: bold;
                display: block;
            }
        }
    }
}

a.help:hover div,
a.help div.show,
.help.onlineText:hover div,
.help.onlineText div.show {
    display: inline;
}

.itemProvisoDetail02-tab a.help div {
    width: 400px;
}

@keyframes tooltipHelp {
    0% {
        top: 0px;
        opacity: 0;
        transform: scale(0, 0);
    }
    100% {
        top: 37px;
        opacity: 1;
        transform: scale(1, 1);
    }
}

@-moz-keyframes tooltipHelp {
    0% {
        bottom: 0px;
        opacity: 0;
        -moz-transform: scale(0, 0);
    }
    100% {
        bottom: 37px;
        opacity: 1;
        -moz-transform: scale(1, 1);
    }
}

@-webkit-keyframes tooltipHelp {
    0% {
        top: 0px;
        opacity: 0;
        -webkit-transform: scale(0, 0);
    }
    100% {
        top: 37px;
        opacity: 1;
        -webkit-transform: scale(1, 1);
    }
}

@-o-keyframes tooltipHelp {
    0% {
        bottom: 0px;
        opacity: 0;
        -o-transform: scale(0, 0);
    }
    100% {
        bottom: 37px;
        opacity: 1;
        -o-transform: scale(1, 1);
    }
}

@keyframes tooltipHelp2 {
    0% {
        bottom: 0px;
        opacity: 0;
        transform: scale(0, 0);
    }
    100% {
        bottom: 58px;
        opacity: 1;
        transform: scale(1, 1);
    }
}

@-moz-keyframes tooltipHelp2 {
    0% {
        bottom: 0px;
        opacity: 0;
        -moz-transform: scale(0, 0);
    }
    100% {
        bottom: 58px;
        opacity: 1;
        -moz-transform: scale(1, 1);
    }
}

@-webkit-keyframes tooltipHelp2 {
    0% {
        bottom: 0px;
        opacity: 0;
        -webkit-transform: scale(0, 0);
    }
    100% {
        bottom: 58px;
        opacity: 1;
        -webkit-transform: scale(1, 1);
    }
}

@-o-keyframes tooltipHelp2 {
    0% {
        bottom: 0px;
        opacity: 0;
        -o-transform: scale(0, 0);
    }
    100% {
        bottom: 58px;
        opacity: 1;
        -o-transform: scale(1, 1);
    }
}

/*---------------------------------------
#freePhoneArea
----------------------------------------*/
#freePhoneArea {
    margin: 0 auto 35px;
    background-color: #EFEEEB;
    clear: both;
    width: 840px;
    border-radius: 4px;
    & .freePhoneBox {
        margin: 0 auto;
        width: 840px;
        padding: 25px 0 25px 340px;
        background: url(/assets/pc/images/free_phone_bg.png) no-repeat 40px 20px;
        background-size: 253px 148px;
        box-sizing: border-box;
        & img {
            display: block;
            &.catch {
                margin-bottom: 15px;
            }
        }
        & .number {
            padding: 0 0 5px 68px;
            background: #EFEEEB url(/assets/pc/images/icon_free_phone_03.png) no-repeat left 7%;
            background-size: 59px auto;
            color: #319F2C;
            font-size: 292%;
            font-weight: bold;
            line-height: 1;
            word-wrap: break-word;
            display: block;
            & img {
                width: auto !important;
            }
        }
        & .poweredBy {
            padding: 0 0 0 68px;
            display: block;
            color: #777;
            font-size: 63%;
        }
    }
}

/*---------------------------------------
.pageTop
----------------------------------------*/
.pageTop a {
    position: fixed;
    right: 33px;
    bottom: 15%;
    z-index: 100;
}

.pageTop a:hover {
    opacity: 0.8;
}

html.scrollNone {
    backface-visibility: hidden;
}

//Vue環境用
.backTop {
    cursor: pointer;
    position: fixed;
    right: 33px;
    bottom: 15%;
    z-index: 100;
    opacity: 0;
    transition: all 1s;
}

.backTop img:hover {
    opacity: 0.8;
}

/*---------------------------------------
footer
----------------------------------------*/
#bottomContents {
    margin-bottom: 20px;
    clear: both;

    .bottom-nav {
        margin: 0;
        padding: 20px 0;
        border: none;
        width: 100%;
        background-color: #f4f4f0;
    }

    .bottom-nav-link {
        margin: 0 auto;
        padding: 0;
        display: table;
        width: 1020px;

        & > li {
            padding-right: 10px;
            display: table-cell;
            width: 255px;
            box-sizing: border-box;

            &:nth-child(n+2) {
                padding-left: 18px;
                border-left: 2px solid #ddd;
            }

            a {
                margin-bottom: 7px;
                padding: 10px 0 10px 46px;
                display: inline-block;
                color: #333;
                font-size: 107%;
                text-decoration: none;
                box-sizing: border-box;
            }
        }

        .navHome a {
            margin: 0 0 15px;
            background: url(/assets/pc/images/nav_home.png) no-repeat 0 center;
            background-size: 40px auto;
        }

        .navHome .navCounseling {
            background: url(/assets/pc/images/nav_counseling.png) no-repeat 0 center;
            background-size: 40px auto;
        }

        input {
            display: none;
        }

        label {
            margin-bottom: 10px;
            padding: 6px 0 6px 46px;
            display: inline-block;
            color: #333;
            font-size: 107%;
            font-weight: bold;
            text-decoration: none;
            box-sizing: border-box;
        }

        .navSearch label {
            background: url(/assets/pc/images/nav_search.png) no-repeat 0 center;
            background-size: 40px auto;
        }

        .navGuide label {
            background: url(/assets/pc/images/nav_guide.png) no-repeat 0 center;
            background-size: 40px auto;
        }

        .navNews a {
            background: url(/assets/pc/images/nav_news.png) no-repeat 0 center;
            background-size: 40px auto;
        }

        .navVoice a {
            background: url(/assets/pc/images/nav_voice.png) no-repeat 0 center;
            background-size: 40px auto;
        }
    }

    .bottom-nav-link-child {
        margin-bottom: 15px;
        display: block;
        line-height: 1.3;
        height: auto;
        transition: 0.8s;
        opacity: 1;

        li {
            a {
                margin-bottom: 5px;
                padding: 0;
                color: #777;
                font-size: 81%;
            }
        }
    }
}

footer {
    clear: both;
}

footer .isoArea {
    margin: 0 auto;
    padding: 20px 0;
    width: 1020px;
}

footer .isoArea p {
    margin: -52px 0 0 121px;
    min-height: 41px;
    height: auto !important;
    height: 41px;
    font-size: 75%;
}

footer .isoArea p span {
    font-size: 100%;
    font-weight: bold;
}

footer .footerLink {
    background-color: #888;
}

footer .footerLink ul {
    margin: 0 auto;
    padding: 12px 0 8px;
    width: 1020px;
    text-align: center;
}

footer .footerLink li {
    color: #FFF;
    display: inline-block;
    *display: inline;
    *zoom: 1;
}

footer .footerLink.vue li { //Vue版フッター用
    margin-right: 3.5px;
    &:last-child {
        margin-right: 0;
    }
}

footer .footerLink li a {
    color: #FFF;
    font-size: 88%;
    vertical-align: top;
}

footer .footerLink li a:hover {
    opacity: 0.8;
}

footer .copyright {
    margin: 0 auto;
    padding: 16px 0;
    width: 1000px;
    font-size: 75%;
    text-align: center;
    line-height: 1;
    display: block;
}

footer .copyright img {
    margin-right: 8px;
    vertical-align: middle;
}


/*---------------------------------------
#loading
----------------------------------------*/
#loading {
    margin: -115px 0 0 -110px;
    padding: 15px 0 0;
    border-radius: 5px;
    box-sizing: border-box;
    border: 3px solid #2A65B7;
    width: 220px;
    height: 230px;
    text-align: center;
    position: fixed;
    left: 50%;
    top: 50%;
    background-color: #FFF;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s all;
    z-index: 999;
}

#loading.showLoading {
    opacity: 1;
    pointer-events: none;
}

#loading img {
    vertical-align: middle;
}

#loading img + img {
    margin-right: 10px;
}


/*---------------------------------------
.diagnosisBanner
----------------------------------------*/
.diagnosisBanner {
    margin: 0 auto 20px;
    display: block;
    width: 1000px;
    line-height: 0;

    &:hover {
        opacity: 0.8;
    }
}

/*---------------------------------------
searchFaqArea
----------------------------------------*/
.searchFaqArea {
    & > h2 {
        margin-bottom: 10px;
        display: block;
        font-size: 125%;
        font-weight: bold;
    }

    & .questionList {
        & li {
            position: relative;
            border-top: 1px dotted #BBBBBB;
            padding: 13px 0 15px;

            &:last-child {
                border-bottom: 1px dotted #BBBBBB;
            }
        }

        &_question {
            display: flex;
        }

        &_questionTitle {
            margin-right: 5px;
            width: 81%;
            display: flex;
            align-items: center;
            position: relative;
            line-height: 1.5;
            padding: 3px 0 0 43px;

            &:before {
                content: "Q";
                position: absolute;
                top: 0;
                left: 0;
                width: 34px;
                height: 34px;
                box-sizing: border-box;
                border-radius: 50%;
                border: 1px solid #BED3F1;
                background: #E0EAF9;
                text-align: center;
                font-size: 113%;
                font-weight: bold;
                color: #2A65B7;
            }
        }

        &_button {
            position: absolute;
            top: 10px;
            right: 0;

            span {
                padding: 4px 10px 4px 30px;
                border: 2px solid #aaa;
                border-radius: 5px;
                display: inline-block;
                line-height: 1.4;
                background: url(/assets/pc/images/icon_sprite.png) no-repeat -356px -121px, linear-gradient(180deg, white 0%, #e8e8e8 100%);
                text-decoration: none;
                color: #333;
                cursor: pointer;

                &::after {
                    content: "\56DE\7B54\3092\898B\308B";
                }

                &:hover {
                    background: #DFDFDF url(/assets/pc/images/icon_sprite.png) no-repeat -356px -121px;
                    background: url(/assets/pc/images/icon_sprite.png) no-repeat -356px -121px, linear-gradient(180deg, rgb(232, 232, 232) 0%, rgb(255, 255, 255) 100%);
                }
            }

            &.on {
                span {
                    background: url(/assets/pc/images/icon_sprite.png) no-repeat -326px -151px, linear-gradient(180deg, white 0%, #e8e8e8 100%);

                    &::after {
                        content: "\9589\3058\308B";
                    }

                    &:hover {
                        background: #DFDFDF url(/assets/pc/images/icon_sprite.png) no-repeat -326px -151px;
                        background: url(/assets/pc/images/icon_sprite.png) no-repeat -326px -151px, linear-gradient(180deg, rgb(232, 232, 232) 0%, rgb(255, 255, 255) 100%);
                    }
                }
            }
        }

        &_answer {
            display: none;
            padding: 17px 0 0 43px;
        }

        &_answerTitle {
            margin-bottom: 3px;
            color: #FF8000;
        }

        &_answerText {
            margin-bottom: 0;
        }
    }
}
/*---------------------------------------
project-modal
----------------------------------------*/
.modalArea {
    padding-bottom: 15px;
    position: fixed;
    top: 20px;
    left: 50%;
    display: none;
    transform: translateX(-50%);
    z-index: 10001;
    &__close {
        position: absolute;
        right: 15px;
        top: 6px;
        text-decoration: none;
        font-family: arial, sans-serif;
        font-weight: bold;
        font-size: 21px;
        color: #E7EDEF;
        &:visited {
            color: #E7EDEF;
        }
    }
    &__lead {
        padding-top: 10px;
        text-align: center;
        line-height: 1.5;
        font-weight: bold;
        font-size: 125%;
        color: #ff8000;
        span {
            font-weight: bold;
            font-size: 80%;
            color: #ff8000;
        }
    }
    &__cta {
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 790px;
        h3 {
            width: 490px;
            line-height: 1.5;
            font-size: 125%;
            font-weight: bold;
            color: #2a65b7;
            background: url(/assets/pc/images/icon_arrow_16.png) no-repeat right center;
            span {
                font-size: 120%;
                font-weight: bold;
                color: #ff8000;
            }
        }
        &-induction {
            width: 490px;
            line-height: 1.5;
            font-size: 125%;
            font-weight: bold;
            color: #2a65b7;
            background: url(/assets/pc/images/icon_arrow_16.png) no-repeat right center;
            span {
                font-size: 120%;
                font-weight: bold;
                color: #ff8000;
            }
        }
        &-city {
            margin-right: 30px;
            width: 278px;
            font-weight: bold;
            color: #ff8000;
        }
        &-button {
            margin-right: 30px;
            width: 240px;
            height: 74px;
            text-indent: 110%;
            white-space: nowrap;
            background: url(/assets/pc/images/detail/btn_01.png) no-repeat top center;
            overflow: hidden;
            display: block;
            &:hover {
                background: url(/assets/pc/images/detail/btn_02.png) no-repeat bottom center;
            }
            a{
                display: block;
                width: 100%;
                height: 100%;
            }
        }
    }
    .freePhoneBox {
        width: 286px;
        display: block;
        color: #444;
        font-weight: bold;
        text-align: center;
        line-height: 1.2;
        b {
            margin: 0 0 7px;
            display: block;
            color: #FF4C00;
            font-weight: bold;
        }
        .number {
            padding: 0 0 0 35px;
            background: url(/assets/pc/images/icon_free_phone.png) no-repeat left 7%;
            color: #319F2C;
            font-size: 224%;
            font-weight: bold;
            line-height: 1;
            word-wrap: break-word;
            display: block;
            letter-spacing: -0.01em;
        }
        .poweredBy {
            display: block;
            color: #777;
            font-size: 60%;
            text-align: right;
        }
    }
    &.leaveModal{
        margin: 0 auto;
        padding-top: 0;
        padding-bottom: 15px;
        position: fixed;
        top: 20px;
        left: 50%;
        width: 900px;
        border-radius: 10px;
        display: none;
        background-color: white;
        -webkit-box-shadow: 0px 0px 4px rgb(0 0 0 / 95%);
        box-shadow: 0px 0px 4px rgb(0 0 0 / 95%);
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        z-index: 10001;
        img {
            width: 100%;
            height: auto;
            display: block;
        }
        ul {
            margin: 0 auto 12px;
            padding: 15px;
            display: flex;
            flex-wrap: wrap;
            width: 790px;
            background-color: #F6F3E9;
            box-sizing: border-box;
            li {
                margin: 0 15px 0 0;
                padding: 15px 10px;
                position: relative;
                width: 243px;
                border-radius: 5px;
                background: #fff;
                box-sizing: border-box;
                h3 {
                    margin-bottom: 10px;
                    text-align: center;
                    font-weight: bold;
                    span {
                        margin-right: 4px;
                        padding: 0 3px;
                        font-size: 81%;
                        background: #FF8000;
                        color: #fff;
                    }
                }
                .lm-title {
                    margin-bottom: 10px;
                    text-align: center;
                    font-weight: bold;
                    span {
                        margin-right: 4px;
                        padding: 0 3px;
                        font-size: 81%;
                        background: #FF8000;
                        color: #fff;
                    }
                }
                p {
                    margin: 0;
                    line-height: 1.5;
                    font-size: 81%;
                }
                &:first-child {
                    .image {
                        margin: 0 auto 8px;
                        width: 62px;
                    }
                }
                &:nth-child(2) {
                    .image {
                        margin: 14px auto 21px;
                        width: 69px;
                    }
                }
                &:last-child {
                    margin: 0;
                    .image {
                        margin: 10px auto;
                        width: 72px;
                    }
                }
            }
        }
    }
}
.modalArea__close {
    position: absolute;
    right: 15px;
    top: 6px;
    text-decoration: none;
    font-family: arial, sans-serif;
    font-weight: bold;
    font-size: 21px;
    color: #E7EDEF;
    &:visited {
        color: #E7EDEF;
    }
}
.leave_overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: none;
    z-index: 10000;
}