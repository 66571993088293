@charset "utf-8";
/*---------------------------------------
footer
----------------------------------------*/
#bottomContents {
    margin-bottom: 20px;
    .bottom-nav {
        margin: 0;
        padding: 20px 0;
        border: none;
        width: 100%;
        background-color: #f4f4f0;
    }

    .bottom-nav-link {
        margin: 0 auto;
        padding: 0;
        display: table;
        width: 1020px;

        & > li {
            padding-right: 10px;
            display: table-cell;
            width: 255px;
            box-sizing: border-box;

            &:nth-child(n+2) {
                padding-left: 18px;
                border-left: 2px solid #ddd;
            }

            a {
                margin-bottom: 7px;
                padding: 10px 0 10px 46px;
                display: inline-block;
                color: #333;
                font-size: 107%;
                text-decoration: none;
                box-sizing: border-box;

                &:hover {
                    text-decoration: underline;
                    opacity: 0.7;
                }
            }
        }

        .navHome a {
            margin: 0 0 15px;
            background: url(/assets/pc/images/nav_home.png) no-repeat 0 center;
            background-size: 40px auto;
        }

        .navHome .navCounseling {
            background: url(/assets/pc/images/nav_counseling.png) no-repeat 0 center;
            background-size: 40px auto;
        }

        input {
            display: none;
        }

        label {
            margin-bottom: 10px;
            padding: 6px 0 6px 46px;
            display: inline-block;
            color: #333;
            font-size: 107%;
            font-weight: bold;
            text-decoration: none;
            box-sizing: border-box;
        }

        .navSearch > a {
            background: url(/assets/pc/images/nav_search.png) no-repeat 0 center;
            background-size: 40px auto;
        }

        .navGuide > a {
            background: url(/assets/pc/images/nav_guide.png) no-repeat 0 center;
            background-size: 40px auto;
        }

        .navNews a {
            background: url(/assets/pc/images/nav_news.png) no-repeat 0 center;
            background-size: 40px auto;
        }

        .navNews a.navFaq {
            background: url(/assets/pc/images/nav_faq.png) no-repeat 0 center;
            background-size: 40px auto;
        }

        .navNews a.navVoice {
            background: url(/assets/pc/images/nav_voice.png) no-repeat 0 center;
            background-size: 40px auto;
        }
    }

    .bottom-nav-link-child {
        margin-bottom: 15px;
        display: block;
        line-height: 1.3;
        height: auto;
        transition: 0.8s;
        opacity: 1;

        li {
            a {
                margin-bottom: 5px;
                padding: 0;
                color: #777;
                font-size: 81%;
                font-weight: 500;
            }
        }
    }
    .relation {
        margin: 0 auto;
        width: 1020px;
        padding: 30px 0 0;
        display: flex;
        align-items: center;
        & p {
            margin-bottom: 0;
        }
        & .relation-logo {
            margin-right: 10px;
            width: 180px;
            display: flex;
            
            align-items: center;
            & img {
                width: 100%;
            }
        }
        & .relation-text {
            margin-right: 19px;
            font-size: 75%;
        }
        & ul {
            display: flex;
            & li {
                margin-right: 20px;
                &:last-child {
                    margin-right: 0;
                }
                & a {
                    display: flex;
                    align-items: center;
                    text-decoration: none;
                    &:hover {
                        opacity:0.7;
                    }
                    & span {
                        margin-right: 8px;
                        display: flex;
                        align-items: center;
                        & img {
                            width: 100%;
                        }
                    }
                    & p {
                        font-weight: bold;
                    }
                }
                &.relation-roujin a span{
                    width: 39px;
                }
                &.relation-gohan a span{
                    width: 34px;
                }
                &.relation-hochouki a span{
                    width: 30px;
                }
            }            
        }
    }
}

footer {
    clear: both;
}

footer .isoArea {
    margin: 0 auto;
    padding: 20px 0;
    width: 1020px;
}

footer .isoArea p {
    margin: -52px 0 0 121px;
    min-height: 41px;
    height: auto !important;
    height: 41px;
    font-size: 75%;
}

footer .isoArea p span {
    font-size: 100%;
    font-weight: bold;
}

footer .footerLink {
    background-color: #888;
}

footer .footerLink ul {
    margin: 0 auto;
    padding: 12px 0 8px;
    width: 1020px;
    text-align: center;
}

footer .footerLink li {
    color: #FFF;
    display: inline-block;
    *display: inline;
    *zoom: 1;
}

footer .footerLink li a {
    color: #FFF;
    font-size: 88%;
    vertical-align: top;
}

footer .footerLink li a:hover {
    opacity: 0.8;
}

footer .copyright {
    margin: 0 auto;
    padding: 16px 0;
    width: 1000px;
    font-size: 75%;
    text-align: center;
    line-height: 1;
    display: block;
}

footer .copyright img {
    margin-right: 8px;
    vertical-align: middle;
}


