@charset "utf-8";
/*---------------------------------------
header
----------------------------------------*/
#scrollTop {
    margin: 0 auto;
    padding: 5px 0 8px;
    width: 1000px;
    height: auto;
    overflow: hidden;
}

header {
    & .headerCatch {
        color: #777;
        font-size: 75%;
        & img {
            margin: -17px 0px 3px auto;
            display: block;
        }
    }
    & .logo {
        padding-top: 2px;
        float: left;
        width: 368px;
        & img {
            display: block;
        }
    }
    & .socialArea {
        padding: 6px 0 0 17px;
        float: left;
        width: 136px;
        & .twitter {
            width: 95px;
            height: 24px;
        }
        & .facebook {
            width: 108px;
        }
    }
    & .link {
        padding: 2px 0 0 10px;
        float: right;
        width: 162px;
        & p {
            margin-bottom: 5px;
            height: 29px;
            text-align: center;
        }
        & a {
            float: left;
            width: 162px;
            height: 36px;
            display: block;
            text-indent: 102%;
            overflow: hidden;
            white-space: nowrap;
            &.buttonRead {
                background: url(/assets/pc/images/header_read.png) no-repeat 0 0;
                &:hover {
                    background: url(/assets/pc/images/header_read_on.png) no-repeat 0 0;
                }
            }
        }
    }
    & .freePhoneBox {
        padding-top: 2px;
        float: right;
        width: 307px;
        & img {
            display: block;
        }
        & b {
            margin-bottom: 4px;
            display: block;
            & .number {
                padding: 0 0 0 42px;
                background: #FFF url(/assets/pc/images/icon_free_phone.png) no-repeat left center;
                color: #319F2C;
                font-size: 235%;
                font-weight: bold;
                line-height: 1;
                word-wrap: break-word;
                display: block;
                border: none;
                text-decoration: none;
            }
        }   
        & .poweredBy {
            display: block;
            color: #777;
            font-size: 63%;
            text-align: right;
        }
          
    }
}

:root header .freePhoneBox b .number {
    font-size: 235%;
}

/*---------------------------------------
#gNav
----------------------------------------*/
#gNav {
    border-top: 1px solid rgb(204, 204, 204);
    border-bottom: 1px solid rgb(204, 204, 204);
    background-image: linear-gradient(rgb(255, 255, 255) 0%, rgb(236, 242, 251) 100%);
    height: 56px;
    & ul {
        margin: 0 auto;
        width: 1002px;
        height: auto;
        overflow: hidden;
        & li {
            border-left: 1px solid #BBB;
            float: left;
            display: flex;
            display: -ms-flexbox;
            justify-content: center;
            align-items: center;
            width: 143px;
            height: 57px;
            box-sizing: border-box;
            & a {
                padding-bottom: 4px;
                text-align: center;
                display: flex;
                display: -ms-flexbox;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                box-sizing: border-box;
                &:hover {
                    border-bottom: 4px solid #FF8000;
                    background-image: linear-gradient(rgb(236, 242, 251) 0%, rgb(255, 255, 255) 100%);
                }
                & img {
                    display: block;
                }
            }
            &.gNavFaq a {
                border-right: 1px solid #BBB;
            }
        }
    }
}

body#pageTop #gNav li.gNavTop a,
body#pageListTop #gNav li.gNavSearch a,
body#pageSearch #gNav li.gNavSearch a,
body#pageCounseling #gNav li.gNavCounseling a,
body#pageGuide #gNav li.gNavGuide a,
body#pageSearch.pageHistory #gNav li.gNavHistory a,
body#pageNews #gNav li.gNavNews a,
body#pageVoice #gNav li.gNavVoice a,
body.pageFaq #gNav li.gNavFaq a {
    border-bottom: 4px solid #2A65B7;
    background-image: linear-gradient(rgb(236, 242, 251) 0%, rgb(255, 255, 255) 100%);
}

body#pageSearch.pageHistory #gNav li.gNavSearch a {
    background-image: linear-gradient(rgb(255, 255, 255) 0%, rgb(236, 242, 251) 100%);
    border-bottom: none;
}

#gNav li.gNavTop a:hover,
#gNav li.gNavSearch a:hover,
#gNav li.gNavGuide a:hover,
#gNav li.gNavCounseling a:hover,
#gNav li.gNavNews a:hover,
#gNav li.gNavFaq a:hover,
body#pageTop #gNav li.gNavTop a,
body#pageListTop #gNav li.gNavSearch a,
body#pageSearch #gNav li.gNavSearch a,
body#pageGuide #gNav li.gNavGuide a,
body#pageCounseling #gNav li.gNavCounseling a,
body#pageNews #gNav li.gNavNews a,
body#pageFaq #gNav li.gNavNews a {
    padding: 14px 0;
    box-sizing: border-box;
}
/*---------------------------------------
.breadcrumbs
----------------------------------------*/
.breadcrumbs {
    margin-bottom: 23px;
    border-bottom: 1px solid #BBB;
    & div {
        margin: 0 auto;
        padding: 11px 0 6px;
        width: 1000px;
        color: #777;
        font-size: 75%;
        & a {
            text-decoration: underline;
        }
    }
}